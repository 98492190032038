<template>
	<div class="login">
		<div class="logo text-center">
			<a href="https://www.dentrodahistoria.com.br/" target="_blank"
				><img
					width="127"
					:src="require('@/assets/logo-ddh-blue@2x.png')"
					alt="Dentro Educa"
					class
			/></a>
		</div>

		<h3>Área do Anjo</h3>
		<h5>Faça seu login para gerir as adoções.</h5>

		<form @submit.prevent="doLogin" class="va-row">
			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="text"
						label="Digite seu e-mail ou CPF "
						id="username"
						name="username"
						maxlength="50"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'usuário'"
						:value="login.username"
						:error="errors.first('username')"
						@input="login.username = $event.target.value"
					/>
				</div>
			</div>

			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="password"
						label="Digite sua senha"
						id="password"
						name="password"
						maxlength="250"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'senha'"
						:value="login.password"
						:error="errors.first('password')"
						@input="login.password = $event.target.value"
					/>

					<div class="recover-link">
						<router-link :to="{ name: 'recover' }" class="btn btn-micro-edit"
							>Esqueceu a senha?</router-link
						>
					</div>
				</div>

				<div class="error text-center">{{ errors.first("error") }}</div>
			</div>

			<div class="d-flex align--center justify--space-between flex xs12">
				<button class="btn btn-primary" type="submit">Entrar</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "login",

		data() {
			return {
				login: {
					username: null,
					password: null,
				},
			};
		},

		methods: {
			doLogin() {
				const self = this;
				this.errors.clear();

				self.$validator.validateAll().then((result) => {
					if (result) {
						axios
							.post(process.env.VUE_APP_ROOT_API + "/users/login", self.login)
							.then(function (response) {
								// if(response.data.success === "true"){
								// } else {
								// }

								let userCredential = {
									token: response.data.data.token,
									project_id: response.data.data.user.project_id,
									role_id: response.data.data.user.role_id,
								};

								if (userCredential.role_id === 1) {
									// godmode
									self.$store.commit("SAVE_USER_CREDENTIALS", userCredential);
									self.$router.push({ name: "peoples-angel" });
								} else if (userCredential.role_id === 10) {
									// school manager
									self.$store.commit("SAVE_USER_CREDENTIALS", userCredential);
									self.$router.push({ name: "peoples-angel" });
								}
							})
							.catch(function (error) {
								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	.error {
		color: red;
	}
	.recover-link {
		position: absolute;
		right: 20px;
		top: 35px;
		width: 150px;

		.btn {
			width: 100%;
		}
	}
	.login {
		font-family: "Nunito";
		width: 35rem;
		text-align: center;

		@include media-breakpoint-down(md) {
			width: 100%;
			padding-right: 2rem;
			padding-left: 2rem;
			.down-container {
				display: none;
			}
		}

		h3 {
			text-align: center;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: 1.33px;
			text-align: center;
			color: #68596e;
			font-weight: 800;
			margin-bottom: 10px;
		}

		h5 {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-bottom: 30px;
		}

		.btn-primary {
			width: 100%;
			//font-family: Nunito;
			font-size: 14px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 4;
			letter-spacing: 0.8px;
			text-align: center;
			color: #ffffff;
			border-radius: 40px;
			text-transform: uppercase;
			margin-top: 30px;
		}
		.down-container {
			margin-top: 3.125rem;
		}
	}
</style>
